import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    params: route.query,
    thumbnail: '',
    detail: {},
    conflict: {},
    chatroomId: '',
    searchWords: computed(() => {
      if (proxy.$Util.isEmpty(state.detail.searchWord)) return []
      return state.detail.searchWord.split(',')
    }),
    runningConflict: computed(() => {
      if (proxy.$Util.isEmpty(state.conflict)) return {}
      return proxy.$_.find(state.conflict, { status: '0' })
    })
  })

  const fnMemberDetail = async () => {
    const res = await proxy.$MarketSvc.postMyPageSaleMarketMemberDetail(
      state.params
    )
    if (res.resultCode === '0000') {
      state.thumbnail = res.thumbnail
      state.detail = res.member
      state.conflict = res.confilct
      state.chatroomId = res.chatroomId
    } else {
      alert(res.resultMsg)
    }
  }

  const fnWizztalk = async () => {
    const params = {}
    params.marketNo = state.params.marketNo
    params.memberSeq = state.detail.memberSeq
    if (proxy.$Util.isEmpty(state.chatroomId)) {
      const res = await proxy.$ChatSvc.postGetMarketChatroomId(params)
      if (res.resultCode === '0000') {
        params.chatroomId = res.chatroomId
        await router.push({
          path: '/market/wizz-talk',
          query: params
        })
      } else {
        alert(res.resultMsg)
      }
    } else {
      const params = {}
      params.marketNo = state.params.marketNo
      params.memberSeq = state.detail.memberSeq
      params.chatroomId = state.chatroomId
      await router.push({
        path: '/market/wizz-talk',
        query: params
      })
    }
    // router.push({
    //   path: '/my-page/wizz-talk/market',
    //   query: {
    //     marketNo: state.detail.marketNo
    //   }
    // })
  }

  const fnRefund = async () => {
    if (!confirm('취소 환불을 승인하시겠습니까?')) return

    const params = {}
    params.seq = state.runningConflict.seq
    const res = await proxy.$MarketSvc.postMyPageCancelRefundAccept(params)
    if (res.resultCode === '0000') {
      alert('취소 환불되었습니다.')
      await fnMemberDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnRefundReject = async (isEdit = 1, item = {}) => {
    const payload = {
      component: proxy.$modalAlertNames.MARKET_REFUND_REJECT,
      data: {
        seq:
          proxy.$ConstCode.BOOLEAN_VALUE.TRUE === isEdit
            ? item.seq
            : state.runningConflict.seq,
        reply: proxy.$ConstCode.BOOLEAN_VALUE.TRUE === isEdit ? item.reply : '',
        isEdit: isEdit
      },
      callback: async () => {
        await fnMemberDetail()
      }
    }
    await fnModalAlert(payload)
  }

  const fnReplyCopy = () => {
    var t = document.createElement('textarea')
    document.body.appendChild(t)
    t.value = state.detail.reply
    t.select()
    document.execCommand('copy')
    document.body.removeChild(t)
    alert('텍스트가 복사되었습니다.')
  }

  const init = async () => {
    await fnMemberDetail()
  }

  init()

  return {
    ...toRefs(state),
    fnMemberDetail,
    fnWizztalk,
    fnRefund,
    fnRefundReject,
    fnReplyCopy
  }
}
