<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="detail_top">
            <div class="market_member_ttl">
              구매자 등록정보
            </div>
            <div class="market_thumb">
              <div class="img_area">
                <img
                  :src="thumbnail[0]"
                  alt=""
                  v-if="!$Util.isEmpty(thumbnail)"
                />
                <img src="@/assets/static/images/no_image.png" alt="" v-else />
              </div>
            </div>
            <div class="party_ttl">{{ detail.title }}</div>
            <!--210810 class명 변경-->
            <div class="party_keyword" v-if="!$Util.isEmpty(searchWords)">
              <span v-for="(item, index) in searchWords" :key="index">{{
                `#${item}`
              }}</span>
            </div>
          </div>
          <div class="lump" style="padding-top: 0;">
            <div class="table_type01">
              <dl>
                <dt>사용일</dt>
                <dd>
                  {{
                    detail.unlimitedCheckYn === 'Y'
                      ? '무제한'
                      : detail.usePeriod
                  }}
                </dd>
              </dl>
              <dl>
                <dt>적립포인트</dt>
                <dd>{{ $Util.formatNumber2(detail.point, { unit: 'P' }) }}</dd>
              </dl>
              <dl>
                <dt>판매가</dt>
                <dd>{{ $Util.formatNumber2(detail.price, { unit: '원' }) }}</dd>
              </dl>
              <dl>
                <dt>수수료</dt>
                <dd>500</dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>사용 기간</dt>
                <dd>
                  <span>{{
                    detail.unlimitedCheckYn === 'N'
                      ? `${$dayjs(detail.regDt).format(
                          'YYYY.MM.DD'
                        )} ~ ${$dayjs(detail.useDt).format('YYYY.MM.DD')}`
                      : '표시없음'
                  }}</span>
                </dd>
              </dl>
              <dl>
                <dt>결제자 정보</dt>
                <dd class="buyer_info">
                  <span class="name" v-if="!$Util.isEmpty(detail.name)">{{
                    detail.name
                  }}</span>
                  <span>{{ detail.nick }}</span>
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          Number(detail.loginType) ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.value
                      },
                      {
                        naver:
                          Number(detail.loginType) ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.value
                      },
                      {
                        google:
                          Number(detail.loginType) ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.value
                      },
                      {
                        apple:
                          Number(detail.loginType) ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.value
                      }
                    ]"
                    v-if="
                      !$Util.isEmpty(detail.loginType) &&
                        detail.loginType !== 'wizzney'
                    "
                  >
                    {{
                      Number(detail.loginType) === 0
                        ? '카카오'
                        : Number(detail.loginType) === 1
                        ? '네이버'
                        : Number(detail.loginType) === 2
                        ? '구글'
                        : '애플'
                    }}
                  </div>
                  <!--'카카오: kakao', '네이버: naver', '구글: google'-->
                </dd>
              </dl>
              <dl>
                <dt>결제 수량</dt>
                <dd class="">
                  {{ detail.quantity }}
                </dd>
              </dl>
              <dl>
                <dt>총 결제액</dt>
                <dd class="space_between">
                  <div
                    v-if="
                      !$Util.isEmpty(detail.usePoint) &&
                        Number(detail.usePoint) > 0
                    "
                  >
                    {{
                      `${$Util.formatNumber2(Number(detail.paymentAmt), {
                        unit: ''
                      })}${
                        !$Util.isEmpty(detail.usePoint) &&
                        Number(detail.usePoint) > 0
                          ? ' - '
                          : ''
                      }`
                    }}
                    <span
                      class="color"
                      v-if="
                        !$Util.isEmpty(detail.usePoint) &&
                          Number(detail.usePoint) > 0
                      "
                      >{{
                        $Util.formatNumber2(detail.usePoint, { unit: 'P' })
                      }}</span
                    >
                  </div>
                  <div
                    class="total"
                    :class="{
                      hide: !(
                        !$Util.isEmpty(detail.usePoint) &&
                        Number(detail.usePoint) > 0
                      )
                    }"
                  >
                    <span class="sec_color">{{
                      $Util.formatNumber2(
                        detail.price * detail.quantity - detail.usePoint,
                        { unit: '' }
                      )
                    }}</span>
                    원
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>결제 방법</dt>
                <dd>{{ detail.paymentType }}</dd>
              </dl>
              <dl>
                <dt>결제일</dt>
                <dd>{{ detail.paymentDt }}</dd>
              </dl>
            </div>
          </div>
          <!--          <div class="lump">
            <div class="table_type02">
              <dl>
                <dt>
                  상품 소개 및 주의사항<span class="table_date">{{
                    detail.regDt
                  }}</span>
                </dt>
                <dd class="editor">
                  <div>{{ detail.contents }}</div>
                </dd>
              </dl>
            </div>
          </div>-->
          <div class="lump bo_zero" v-if="detail.optionYn === 'Y'">
            <!--210820 bo_zero 추가-->
            <div class="table_type02">
              <dl>
                <!--210810수정-->
                <dt>
                  정보 입력
                  <!--                  <span class="table_date"
                    >{{ $dayjs(detail.optionDt).format('YYYY.MM.DD') }}
                    &lt;!&ndash; <span class="color_error"> N</span>&ndash;&gt;
                  </span>-->
                </dt>
                <!--210810수정-->
                <dd class="editor">
                  <div>
                    <!--붙여쓰기-->{{ detail.msg
                    }}<!--붙여쓰기-->
                  </div>
                  <div style="white-space: inherit; margin-top: 1rem;">
                    <label class="input" @click="fnReplyCopy">
                      <!--210810 파티장은 정보입력란에 입력할 수 없고 파티원이 작성해준 내용만을 확인하고 선택 시 복사가 돼야합니다.-->

                      <!--정보를 입력하지 않았을 때 -->
                      <!--<textarea class="info_none" disabled>파티원이 정보를 입력하지 않았습니다. 위즈톡으로 정보 요청 입력이 가능하도록 확인해주세요.</textarea>-->

                      <!--정보를 입력했을 때 -->
                      <textarea
                        class="info_wrote"
                        disabled=""
                        v-model="detail.reply"
                      ></textarea>
                      <!--//210810 추가 끝-->
                    </label>
                  </div>
                </dd>
                <!--//210810수정끝-->
              </dl>
            </div>
          </div>
          <div class="blue_line" v-if="detail.status === '0'">
            <div class="inner">
              <div>
                <span class="sec_color"
                  >결제 승인 대기중입니다. 결제일로부터 최대 3일 소요될
                  수있습니다.</span
                >
              </div>
            </div>
          </div>
          <div
            class="lump"
            style="border: 0; padding-top: 2rem;"
            v-if="detail.status === '3'"
          >
            <div class="table_type02">
              <dl>
                <dt>
                  승인 완료<span class="table_date"
                    >{{ $dayjs(detail.approvalDt).format('YYYY.MM.DD')
                    }}<span class="error" v-show="detail.approvalBadge === '1'">
                      N</span
                    >
                  </span>
                </dt>
                <dd class="editor">
                  <div>
                    <!--붙여쓰기-->결제가 (자동) 승인 되었습니다.<!--붙여쓰기-->
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="status" v-if="!$Util.isEmpty(conflict)">
            <div class="inner" v-for="(item, index) in conflict" :key="index">
              <div>
                <div>
                  <dl class="status_sec">
                    <dt class="status_ttl">
                      취소/환불 신청
                      <span class="status_date">{{
                        $dayjs(item.regDt).format('YYYY.MM.DD')
                      }}</span>
                    </dt>
                    <dd class="status_main">
                      <div class="status_sec_ttl">
                        <div class="reason">
                          <span class="sec_color">{{
                            Number(item.option) === 0
                              ? '단순 변심'
                              : '파티장 과실'
                          }}</span>
                        </div>
                      </div>
                      <div class="editor">
                        <div v-html="item.msg"></div>
                      </div>
                    </dd>
                    <dd class="status_sub" style="margin-top: 1rem">
                      <div>
                        · 파티장은 취소/환불 신청일로부터 3일 이내에 취소/환불
                        또 는 반려 처리해야 합니다. 3일 이후는 자동 취소/환불
                        승인됩니다.
                      </div>
                      <div>
                        · 취소/환불 승인은 신청일 기준으로 처리됩니다.
                      </div>
                      <div>
                        · 취소 환불 후 파티원은 잔여일 수 기준 자동으로 추가
                        모집 진행됩니다.
                      </div>
                    </dd>
                  </dl>
                  <dl class="status_sec" v-if="item.status === '1'">
                    <dt class="status_ttl">
                      취소/환불 안내
                      <span
                        class="status_date"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        >{{
                          $dayjs(item.replyRegDt).format('YYYY.MM.DD')
                        }}</span
                      >
                    </dt>
                    <dd class="status_main">
                      <div class="status_sec_ttl">
                        <div class="reason">
                          <span class="color">승인 완료</span>
                        </div>
                      </div>
                      <div class="editor">
                        <div>
                          파티장 (자동) 승인에 따라 결제내역이 (부분)
                          취소되었습니다.
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl class="status_sec" v-if="item.status === '2'">
                    <dt class="status_ttl">
                      취소/환불 안내
                      <span
                        class="status_date"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        >{{
                          $dayjs(item.replyRegDt).format('YYYY.MM.DD')
                        }}</span
                      >
                    </dt>
                    <dd class="status_main">
                      <div class="status_sec_ttl">
                        <div class="reason">
                          <span class="error">신청 반려</span>
                        </div>
                        <button
                          class="btn_revise"
                          @click="
                            fnRefundReject($ConstCode.BOOLEAN_VALUE.TRUE, item)
                          "
                        ></button>
                      </div>
                      <div class="editor">
                        <div v-html="item.reply"></div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div class="lump" style="border: 0;" v-if="item.status === '3'">
                <div class="table_type02">
                  <dl>
                    <dt>
                      분쟁 취소 안내
                      <span
                        class="table_date"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        >{{ $dayjs(item.regDt).format('YYYY.MM.DD') }}</span
                      >
                    </dt>
                    <dd class="editor">
                      <div>
                        <!--붙여쓰기-->취소 환불 신청이 취소되었습니다.<!--붙여쓰기-->
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div class="con_btn">
            <div class="btn_wrap">
              <button
                class="btn bo"
                @click="fnWizztalk"
                v-if="detail.wizztalkYn === 'Y'"
              >
                위즈톡<span class="wiz_icon"></span>
              </button>
              <button
                class="btn bo"
                @click="fnRefund"
                v-if="!$Util.isEmpty(runningConflict)"
              >
                취소/환불
              </button>
            </div>
            <div class="btn_wrap">
              <button
                class="btn bo"
                @click="fnRefundReject"
                v-if="!$Util.isEmpty(runningConflict)"
              >
                신청 반려
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-market-member-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
